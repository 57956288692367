import axios from 'axios'
// import { Toast, Dialog } from 'vant'
import router from '@/router/index.js'
const Authorization = sessionStorage.getItem('Authorization')
axios.defaults.headers.common['Authorization'] = Authorization
import cookies from "vue-cookies";

// 创建一个axios实例
const service = axios.create({
	//baseURL: 'https://bl-test.0123china.cn/api/', //服务器测试地址
	baseURL: 'https://jqzwapi.weizu777.cn/api/', //服务器生产地址
	//baseURL: "https://4b992516z7.wocp.fun/api/", //胥超本地环境

  timeout: 100000
})

// 请求拦截器
service.interceptors.response.use(
  config => {
 console.log(cookies.get("token"),'------1---')
 if(config.data.code == 435){
   // localStorage.clear()
   router.replace('/login')
 }
    // 这里做些发送请求前的事情
    return config
  },
  error => {
	  console.log(error,'---2-2-2--')
    if(error.request.status == 435){
      router.replace('/login')
    }
    return Promise.reject(error)
  }
)


export default service